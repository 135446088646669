[data-theme='light'] .rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #694CE2;
  --rdp-background-color: #F2EDF5;

  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

[data-theme='dark'] .rdp {
  --rdp-cell-size: 40px;
  --rdp-cell-color: black;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color: #694CE2;
  --rdp-background-color: #F2EDF5;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

[data-theme='dark'] .rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover{
  color: #262736;
  font-weight: 700;
}

[data-theme='light'] .rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover{
  color: white;
  font-weight: 700;
}


[data-theme='dark'] .rdp-button:hover:not([disabled]){
  color: #694CE2;
  background-color: #CA97F8;
  font-weight: 700;
}

[data-theme='light'] .rdp-button:hover:not([disabled]):not(.rdp-day_selected){
  color: #694CE2;
  font-weight: 700;
}